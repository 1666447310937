import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import { throwErrorMessage } from '../utils/api';
import AssistantsApi from '../../api/assistants';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getFacts($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getFact: $state => factKey => {
    const [fact] = $state.records.filter(
      record => record.id === String(factKey)
    );
    return fact || {};
  },
  getActiveFact($state) {
    return $state.records[0] || { content: '' };
  }
};

export const actions = {
  fetchFacts: async ({ commit }, rookooAgentId) => {
    commit(types.SET_FACT_UI_FLAG, { isFetching: true });
    try {
      const response = await AssistantsApi.fetchFacts(rookooAgentId);
      commit(types.SET_FACTS, response.data.items);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_FACT_UI_FLAG, { isFetching: false });
    }
  },
  createFact: async ({ commit }, { rookooAgentId, fact }) => {
    commit(types.SET_FACT_UI_FLAG, { isCreating: true });
    try {
      const response = await AssistantsApi.createFact(rookooAgentId, { fact });
      commit(types.ADD_FACT, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_FACT_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  deleteFact: async ({ commit }, { rookooAgentId, factKey }) => {
    commit(types.SET_FACT_UI_FLAG, { isDeleting: true });
    try {
      await AssistantsApi.destroyFact(rookooAgentId, factKey);
      commit(types.DELETE_FACT, factKey);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_FACT_UI_FLAG, { isDeleting: false });
    }
  },
  updateFact: async ({ commit }, { rookooAgentId, factKey, fact }) => {
    commit(types.SET_FACT_UI_FLAG, { isUpdating: true });
    try {
      const response = await AssistantsApi.updateFact(rookooAgentId, factKey, { fact });
      commit(types.ADD_FACT, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_FACT_UI_FLAG, { isUpdating: false });
    }
  },
};

export const mutations = {
  [types.SET_FACT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_FACTS]: MutationHelpers.set,
  [types.ADD_FACT]: MutationHelpers.setSingleRecord,
  [types.DELETE_FACT]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};