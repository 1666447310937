/* global axios */

import ApiClient from './ApiClient';

class AssistantsApi extends ApiClient {
  constructor() {
    super('rookoo_agents', { accountScoped: true, apiVersion: 'v2' });
  }

  generate(data) {
    return axios.post(`${this.url}/generate`, data);
  }

  fetchInstructions(id) {
    return axios.get(`${this.url}/${id}/instructions`);
  }

  createInstruction(id, { instruction }) {
    return axios.post(`${this.url}/${id}/instructions`, { instruction });
  }

  destroyInstruction(id, instructionId) {
    return axios.delete(`${this.url}/${id}/instructions/${instructionId}`);
  }

  updateInstruction(id, instructionId, instruction) {
    return axios.patch(`${this.url}/${id}/instructions/${instructionId}`, { "instruction": instruction });
  }
  
  fetchFacts(id) {
    return axios.get(`${this.url}/${id}/facts`);
  }

  fetchFact(id, factKey) {
    return axios.get(`${this.url}/${id}/facts/${factKey}`);
  }

  createFact(id, { fact }) {
    return axios.post(`${this.url}/${id}/facts`, { fact });
  }

  destroyFact(id, factKey) {
    return axios.delete(`${this.url}/${id}/facts/${factKey}`);
  }

  updateFact(id, factKey, { fact }) {
    return axios.patch(`${this.url}/${id}/facts/${factKey}`, { fact });
  }
}

export default new AssistantsApi();
