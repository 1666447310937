<template>
  <div>
    <div v-if="allowTransparent" class="flex mb-2">
      <input
        type="radio"
        :id="id"
        :name="colorInputName"
        :value="false"
        :checked="!isTransparent"
        @input="setColor"
        class="mr-2"
      />
      <label class="mr-2" :for="id">Color</label>
      <input
        type="radio"
        :name="colorInputName"
        :id="transparentInputId"
        :value="true"
        :checked="isTransparent"
        @input="setTransparent"
        class="mr-2"
      />
      <label class="mr-2" :for="transparentInputId">Transparent</label>
    </div>
    <div class="colorpicker" v-if="!isTransparent">
      <div
        class="colorpicker--selected"
        :style="`background-color: ${value}`"
        @click.prevent="toggleColorPicker"
      />
      <chrome
        v-if="isPickerOpen"
        v-on-clickaway="closeTogglePicker"
        :disable-alpha="true"
        :value="value"
        class="colorpicker--chrome"
        @input="updateColor"
      />
    </div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color';

export default {
  components: {
    Chrome,
  },
  props: {
    id: {
      type: String,
      default: 'color',
    },
    value: {
      type: String,
      default: '',
    },
    allowTransparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPickerOpen: false,
    };
  },
  computed: {
    colorInputName() {
      return `${this.id}Option`;
    },
    transparentInputId() {
      return `${this.id}-transparent`;
    },
    isTransparent() {
      return this.value === 'transparent';
    },
  },
  methods: {
    closeTogglePicker() {
      if (this.isPickerOpen) {
        this.toggleColorPicker();
      }
    },
    toggleColorPicker() {
      this.isPickerOpen = !this.isPickerOpen;
    },
    updateColor(e) {
      this.$emit('input', e.hex);
    },
    setColor() {
      this.$emit('input', '#ffffff');
    },
    setTransparent() {
      this.$emit('input', 'transparent');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.colorpicker {
  position: relative;
}

.colorpicker--selected {
  @apply border border-solid border-slate-50 dark:border-slate-600 rounded cursor-pointer h-8 w-8 mb-4;
}

.colorpicker--chrome.vc-chrome {
  @apply shadow-lg -mt-2.5 absolute z-[9999] border border-solid border-slate-75 dark:border-slate-600 rounded;

  ::v-deep {
    input {
      @apply bg-white dark:bg-white;
    }
  }
}
</style>
