class RecordsAPI {

  apiVersion = '';

  constructor() {
    this.apiVersion = `/api/v1`;
  }

  get accountIdFromRoute() {
    const isInsideAccountScopedURLs =
      window.location.pathname.includes('/app/accounts');

    if (isInsideAccountScopedURLs) {
      return window.location.pathname.split('/')[3];
    }

    return '';
  }

  url(knowledgebaseId) {
    return `${this.baseUrl(knowledgebaseId)}/records`;
  }

  baseUrl(knowledgebaseId) {
    let url = this.apiVersion;
    url = `${url}/accounts/${this.accountIdFromRoute}/knowledgebases/${knowledgebaseId}`;

    return url;
  }

  buildBaseUrl(knowledgebaseId) {
    return `/${knowledgebaseId}`
  }

  get(knowledgebaseId) {
    return axios.get(this.url(knowledgebaseId));
  }

  show(knowledgebaseId, recordId) {
    return axios.get(`${this.url(knowledgebaseId)}/${recordId}`);
  }

  create(knowledgebaseId, data) {
    return axios.post(this.url(knowledgebaseId), data);
  }

  update(knowledgebaseId, recordId, data) {
    return axios.post(`${this.url(knowledgebaseId)}/${recordId}`, data);
  }

  delete(knowledgebaseId, recordId) {
    return axios.delete(`${this.url(knowledgebaseId)}/${recordId}`);
  }

  deleteAll(knowledgebaseId) {
    return axios.delete(`${this.url(knowledgebaseId)}/delete_all`);
  }

}

export default new RecordsAPI();
